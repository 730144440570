import {initializeApp} from "firebase/app";
import {getFirestore} from "firebase/firestore";
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBGoyrZASRb1MRPuMyKd8BRgT4bA4S2414",
    authDomain: "iog-app-fff47.firebaseapp.com",
    projectId: "iog-app-fff47",
    storageBucket: "iog-app-fff47.appspot.com",
    messagingSenderId: "77897763505",
    appId: "1:77897763505:web:3582ce03044760cd927090"
};


class FirebaseClient {
    static firebaseApp = initializeApp(firebaseConfig);
    static db = getFirestore(FirebaseClient.firebaseApp);
    static auth = getAuth(FirebaseClient.firebaseApp);

    static async authenticate() {
        if (!FirebaseClient.auth.currentUser || !FirebaseClient.auth.currentUser.isAuthenticated()) {
            let result = await signInWithEmailAndPassword(FirebaseClient.auth, 'test.website@iog-austria.at', 'U6K74SAJLiY3W1xz');
            console.log(result);
        }
    }
}

export default FirebaseClient;
