<template>
  <v-dialog transition="dialog-bottom-transition" persistent v-model="show" max-width="500px">
    <template>
      <v-card>
        <v-toolbar color="primary" dark>
          <v-icon large class="mr-4">mdi-email-outline</v-icon>
          <h3 style="color: white">Teilnehmer:innen Infos</h3>
        </v-toolbar>
        <v-card-text class="pa-2">
          <v-spacer></v-spacer>
          <v-form ref="form" v-model="valid" lazy-validation class="pa-2">
            <v-text-field
                v-model="firstName"
                :rules="firstNameRules"
                label="Vorname"
                required
            ></v-text-field>
            <v-text-field
                v-model="lastName"
                :rules="lastNameRules"
                label="Nachname"
                required
            ></v-text-field>

            <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-Mail"
                required
            ></v-text-field>
            <div color="error">{{ errorMessage }}</div>
            <v-spacer></v-spacer>
          </v-form>

        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="closeDialog">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="subscribe" :disabled="!valid">Anmelden</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
import FirebaseClient from '../firebase';
import {collection, addDoc} from "firebase/firestore";

export default {
  name: 'rsvp-details',
  props: {
    show: Boolean,
    isAttending: Boolean,
    eventId: String,
  },
  data: () => ({
    valid: true,
    firstName: '',
    firstNameRules: [
      v => !!v || 'Vorname fehlt',
    ],
    lastName: '',
    lastNameRules: [
      v => !!v || 'Nachname fehlt',
    ],
    email: '',
    emailRules: [
      v => !!v || 'E-Mail wird benötigt',
      v => /.+@.+\..+/.test(v) || 'Ungültige E-Mail Adresse',
    ],
    select: null,
    rsvpDetails: false,
    checkbox: false,
    errorMessage: '',
    nextPath: '',
  }),

  methods: {
    closeDialog() {
      this.firstName = '';
      this.lastName = '';
      this.email = '';
      this.show = false;
      console.log(this.nextPath);
      this.$router.push(this.nextPath);
      this.$router.go(0);
    },
    async subscribe() {
      this.errorMessage = '';
      let now = new Date();
      this.$refs.form.validate();
      if (!this.valid) return;
      this.nextPath = this.$route.path + '?sender_campaign=' + this.eventId + '&entered=true';
      await addDoc(collection(FirebaseClient.db, 'rsvps'), {
        attending: this.isAttending,
        eventId: this.$route.query.sender_campaign,
        isAnonymous: true,
        email: this.email,
        firstname: this.firstName,
        lastName: this.lastName,
        timestamp: now.toISOString(),
      });
      this.closeDialog();
    },
  },
};
</script>
<style>
/* clear */

img {
  max-width: 100%;
  vertical-align: bottom;
}

a {
  color: #444;
  text-decoration: none;
}

a:hover {
  color: #444;
}

a:focus {
  outline: 0;
  text-decoration: none;
}

a:hover,
a:active {
  text-decoration: none;
  outline: 0;
}

input:focus {
  outline: 0;
  border: 1px solid #04A4CC;
}

body {
  font-family: 'Roboto', 'Calibri', sans-serif;
  font-weight: 400;
}

h1,
h2,
h3 {
  font-family: 'Lato', 'Roboto', 'Calibri', sans-serif;
  font-weight: 700;
  color: #f7a600;
}

h1 {
  text-transform: uppercase;
  font-size: 3em;
}

.nav a {
  padding-right: 1em;
  text-decoration-line: none;
}

.nav a:hover {
  color: white;
}

h1, h2, h3, h4, h5, h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

h1, h2, h3 {
  font-family: 'Lato', 'Roboto', 'Calibri', sans-serif;
  font-weight: 700;
  color: #f7a600;
}

h2, h3 {
  font-weight: 600;
}

h3 {
  font-size: 24px;
  margin-top: 50px;
  margin-bottom: 50px;
  text-transform: uppercase;
  color: black;
  letter-spacing: 2px;
}

</style>